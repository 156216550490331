<template>
  <div class="safe_Auth">
    <el-page-header @back="goBack" content="身份认证">
    </el-page-header>
    <div v-if="isExamin" class="safe_content">
      <el-alert :title="`资料审核未通过，请重新填写提交！错误信息：${identityAuthData.verify_message}`" type="error" v-if="identityAuthData.status === '审核不通过'" :closable="false">
      </el-alert>
      <el-form ref="identityAuthRef" :model="identityAuthData" :rules="identityAuthRules" label-width="140px" :disabled="Disable">
        <!-- 真实姓名 -->
        <el-form-item label="真实姓名:" prop="truename">
          <el-input type="text" size="medium" v-model="identityAuthData.truename"></el-input>
        </el-form-item>
        <!-- 身份证号码 -->
        <el-form-item label="身份证号码:" prop="idcart_num">
          <el-input type="text" size="medium" v-model="identityAuthData.idcart_num"></el-input>
        </el-form-item>
        <!-- 手持身份证照 -->
        <el-form-item label="手持身份证照:" prop="idcard_image1">
          <el-upload action="/user/upload/idcard" :show-file-list="false" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="1" :headers="myHeaders" name="image" :on-success="handleImage1Success">
            <img v-if="identityAuthData.idcard_image1" :src="identityAuthData.idcard_image1" class="licence" />
            <i v-else class="el-icon-plus licence-uploader-icon"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
        </el-form-item>
        <!-- 身份证正面照 -->
        <el-form-item label="身份证正面照" prop="idcard_image2">
          <el-upload action="/user/upload/idcard" :show-file-list="false" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="1" :headers="myHeaders" name="image" :on-success="handleImage2Success">
            <img v-if="identityAuthData.idcard_image2" :src="identityAuthData.idcard_image2" class="licence" />
            <i v-else class="el-icon-plus licence-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 身份证反面照 -->
        <el-form-item label="身份证反面照" prop="idcard_image3">
          <el-upload action="/user/upload/idcard" :show-file-list="false" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :limit="1" :headers="myHeaders" name="image" :on-success="handleImage3Success">
            <img v-if="identityAuthData.idcard_image3" :src="identityAuthData.idcard_image3" class="licence" />
            <i v-else class="el-icon-plus licence-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 提交 -->
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">{{btnText}}</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="!isExamin">
      <el-form label-width="100px">
        <!-- 真实姓名 -->
        <el-form-item label="真实姓名:">
          <span>{{identityAuthData.truename}}</span>
        </el-form-item>
        <!-- 身份证号码 -->
        <el-form-item label="身份证号:">
          <span>{{identityAuthData.idcart_num}}</span>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import { userSafeSubmitApi, getUserExaminApi } from '@/api/user'
  export default {
    name: 'safe_Auth',
    data() {
      return {
        myHeaders: {
          authorization: `Bearer ${localStorage.sefpc_token}`
        },
        identityAuthData: {
          truename: '',
          idcart_num: '',
          idcard_image1: '',
          idcard_image2: '',
          idcard_image3: ''
        },
        identityAuthRules: {
          truename: [
            { required: true, message: '请输入真实姓名', trigger: 'blur' }
          ],
          idcart_num: [
            { required: true, message: '请输入身份证号码', trigger: 'blur' }
          ],
          idcard_image1: [
            { required: true, message: '请上传手持身份证照', trigger: 'blur' }
          ],
          idcard_image2: [
            { required: true, message: '请上传身份证正面照', trigger: 'blur' }
          ],
          idcard_image3: [
            { required: true, message: '请上传身份证反面照', trigger: 'blur' }
          ]
        },
        dialogImageUrl: '',
        dialogVisible: false,
        disabled: false,
        uploadDisabled: false,
        isExamin: true,
        Disable: false,
        btnText: '确认提交'
      }
    },
    created() {
      this.getUserExamin()
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      // 获取实名认证信息
      getUserExamin() {
        getUserExaminApi().then(res => {
          console.log(res, '实名认证信息')
          if (!res) return
          this.isExamin = res.data.status == 90004
          if (this.isExamin) return
          this.isExamin = false
          this.identityAuthData = res.data.result
          if (res.data.result.status == "审核中") this.btnText = '资料审核中'
          if (res.data.result.status != "审核不通过") this.Disable = true
          if (res.data.result.status != "审核通过") this.isExamin = true
        })
      },
      handleImage1Success(res) {
        this.identityAuthData.idcard_image1 = res.result.fileName[0]
      },
      handleImage2Success(res) {
        this.identityAuthData.idcard_image2 = res.result.fileName[0]
      },
      handleImage3Success(res) {
        this.identityAuthData.idcard_image3 = res.result.fileName[0]
      },
      // 提交
      handleSubmit() {
        this.$refs.identityAuthRef.validate((valid) => {
          if (!valid) return false
          userSafeSubmitApi(this.identityAuthData).then(res => {
            console.log(res)
            if (res.data.status == 90003) return this.$message.error(res.data.message)
            this.$message.success(res.data.message)
            this.getUserExamin()
          })
        });
      },
      handleRemove(file) {
        console.log(file);
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      }
    }
  }
</script>

<style lang="less" scoped>
  .safe_Auth {
    text-align: left;
    line-height: normal;

    .el-page-header {
      margin: 30px;
    }

    .safe_content {
      margin-right: 50px;
    }

    .licence-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 178px;
      height: 178px;
    }

    .licence-uploader:hover {
      border-color: #409eff;
    }

    .licence-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      line-height: 178px;
      text-align: center;
    }

    .licence {
      width: 178px;
      height: 178px;
      display: block;
    }
  }

  .disabled .el-upload--picture-card {
    display: none;
  }
</style>
